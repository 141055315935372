import React, { useState } from "react";
import ColorSwatch from "items/ColorSwatch";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import { markMatchedText } from "utilities/markText";

const ColorOption = ({ color, colorInSearchBox }) => {
  const { dgiStyle, colorQuery } = useParams();
  const navigate = useNavigate();
  const isSelectedColor = color.toLowerCase() === colorQuery.toLowerCase();

  const onColorChange = () => {
    const encodedColor = encodeURIComponent(color);
    // Replace allows us to navigate back to the previous page without going
    // through every selected color
    navigate(`/product/${dgiStyle}/${encodedColor}`, { replace: true });
  };

  const markedText = markMatchedText(color, colorInSearchBox);

  return (
    <Box
      className={`flex justify-start items-center 
        flex-shrink-0 flex-grow-0 w-[130px]
        gap-[4px] cursor-pointer rounded-soft`}
      sx={{
        "&:hover": {
          borderColor: !isSelectedColor && "rgba(0, 0, 0, 0.2)",
        },
        border: isSelectedColor
          ? "1px solid rgba(0, 0, 0, 0.8)"
          : "1px solid transparent",
      }}
      onClick={onColorChange}
    >
      <ColorSwatch
        id={`${dgiStyle}-${color}-select-btn`}
        key={`${dgiStyle}-${color}-select-btn`}
        dgiStyle={dgiStyle}
        color={color}
        hasSwatchText={true}
        disabled={true}
      />
      <div>
        <span className="font-light text-almostSM">{markedText}</span>
      </div>
    </Box>
  );
};

const ColorSelector = ({
  filteredColorNames,
  condensedColorsOnDisplay,
  colorInSearchBox,
  setColorInSearchBox,
}) => {
  const { colorQuery } = useParams();
  const [expanded, setExpanded] = useState(false);

  const handleColorSearchChange = (e) => {
    setColorInSearchBox(e.target.value);
  };

  const renderNoColorsMessage = filteredColorNames.length === 0 && (
    <div className="flex justify-start items-center w-full h-full ml-[10px] mt-[10px]">
      <Typography className="text-base">
        No matching colors. Try searching for another color.
      </Typography>
    </div>
  );

  const colorsPrompt = expanded ? "Hide colors" : "See all colors";

  // Determine if we need to show the expand button
  const showButton = filteredColorNames.length > 10;

  return (
    <Box className="flex flex-col gap-[8px] w-full">
      <Typography variant="h6">
        <span className="text-lg">Color: </span>
        <span className="text-lg font-light">{colorQuery}</span>
      </Typography>
      <TextField
        onFocus={() => setExpanded(true)}
        autoComplete="off"
        placeholder="Search by color name..."
        value={colorInSearchBox}
        onChange={handleColorSearchChange}
        className="mr-[20px] mt-[5px] mb-[10px]"
        sx={{
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.1)",
            "&:hover fieldset": {
              borderColor: "rgb(2 129 192)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgb(2 129 192)",
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon className="text-grayscaleSmoke mr-[6px]" />
          ),
        }}
      />

      <Box className="relative overflow-hidden">
        <Box 
          className={`
            grid grid-cols-4 gap-[2px] 
            transition-all duration-500 ease-in-out
            ${expanded ? 'translate-y-0' : 'translate-y-0'}
          `}
          style={{
            maxHeight: expanded ? '1000px' : '150px',
            transition: 'max-height 500ms ease-in-out, transform 500ms ease-in-out'
          }}
        >
          {filteredColorNames.map((c, index) => (
            <ColorOption
              color={c}
              key={index}
              colorInSearchBox={colorInSearchBox}
              className={`
                transition-all duration-500 ease-in-out
                ${!expanded && index >= 10 ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}
                ${!expanded && !condensedColorsOnDisplay.includes(c)
                  ? 'opacity-30'
                  : 'opacity-100'
                }
              `}
            />
          ))}
          {renderNoColorsMessage}
        </Box>
        {!expanded && showButton && (
          <Box className="absolute bottom-0 left-0 right-0 h-[30px] bg-gradient-to-t from-white to-transparent" />
        )}
      </Box>

      {showButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "25px",
            paddingTop: "15px",
          }}
        >
          <IconButton
            onClick={() => setExpanded(!expanded)}
            sx={{
              color: "rgb(2 129 192)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgb(2 129 192)",
              borderRadius: "5px",
              gap: "5px",
            }}
            className="hover:bg-grayscaleSilver"
          >
            {expanded ? <RemoveIcon /> : <AddIcon />}
            <Typography className="text-xl" sx={{ color: "inherit" }}>
              {colorsPrompt}
            </Typography>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ColorSelector;
